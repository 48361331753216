.favorite-wrapper, .no-favorites {
  margin: 2.2rem;
}

.remove-container {
  margin: 0 auto;
  width: 40rem;
}

.remove {
  text-align: right;
  color: rgb(216, 0, 12);
  cursor: pointer;
}
:root {
  --form-gradient: linear-gradient(rgb(51, 51, 51), rgb(34, 34, 34));
  --shadow-color: 0 -0.0625rem 0 rgb(0, 0, 0);
}

.form-wrapper form {
  background: linear-gradient(rgb(27, 27, 27), rgb(17, 17, 17));
  border: 0.0625rem solid rgb(0, 0, 0);
  border-radius: 0.3125rem;
  box-shadow: inset 0 0 0 0.0625rem rgb(39, 39, 39);
  display: inline-block;
  margin: 1.3rem auto 0;
  padding: 1.85rem;
  position: relative;
  z-index: 1;
}

.form-wrapper input {
  background: var(--form-gradient);
  border: 0.0625rem solid rgb(68, 68, 68);
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: 0 0.125rem 0 rgb(0, 0, 0);
  color: rgb(136, 136, 136);
  display: block;
  float: left;
  font-family: 'Cabin', helvetica, arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  height: 3rem;
  margin: 0;
  padding: 0 0.625rem;
  text-shadow: var(--shadow-color);
  width: 12.5rem;
}

.form-wrapper input:-webkit-input-placeholder,
.form-wrapper input:-moz-placeholder {
  color: rgb(136, 136, 136);
}

.form-wrapper input:focus {
  animation: glow 800ms ease-out infinite alternate;
  background: linear-gradient(rgb(51, 57, 51), rgb(34, 41, 34));
  border-color: rgb(51, 153, 51);
  box-shadow: 0 0 5px rgba(0, 255, 0, .2), inset 0 0 0.3125rem rgba(0, 255, 0, .1), 0 0.125rem 0 rgb(0, 0, 0);
  color: rgb(238, 255, 238);
  outline: none;
}

.form-wrapper input:focus::-webkit-input-placeholder,
.form-wrapper input:focus:-moz-placeholder {
  color: rgb(238, 255, 238);
}

.form-wrapper input[type="submit"] {
  background: var(--form-gradient);
  box-sizing: border-box;
  border: 0.0625rem solid rgb(68, 68, 68);
  border-left-color: rgb(0, 0, 0);
  border-radius: 0 0.3125rem 0.3125rem 0;
  box-shadow: 0 0.125rem 0 rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  display: block;
  float: left;
  font-family: 'Cabin', helvetica, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  height: 3rem;
  line-height: 2.5rem;
  margin: 0;
  padding: 0;
  position: relative;
  text-shadow: var(--shadow-color);
  width: 5rem;
}

.form-wrapper input[type="submit"]:hover,
.form-wrapper input[type="submit"]:focus {
  cursor: pointer;
  background: linear-gradient(rgb(57, 57, 57), rgb(41, 41, 41));
  color: rgb(85, 255, 85);
  outline: none;
}

.form-wrapper input[type="submit"]:active {
  background: linear-gradient(rgb(57, 57, 57), rgb(41, 41, 41));
  box-shadow: 0 0.0625rem 0 rgb(0, 0, 0), inset 0.0625rem 0 0.0625rem rgb(34, 34, 34);
  top: 0.0625rem;
}

@keyframes glow {
  0% {
    border-color: rgb(51, 153, 51);
    box-shadow: 0 0 0.3125rem rgba(0, 255, 0, .2), inset 0 0 0.3125rem rgba(0, 255, 0, .1), 0 0.125rem 0 rgb(0, 0, 0);
  }

  100% {
    border-color: rgb(102, 255, 102);
    box-shadow: 0 0 1.25rem rgba(0, 255, 0, .6), inset 0 0 0.625rem rgba(0, 255, 0, .4), 0 0.125rem 0 rgb(0, 0, 0);
  }
}

:root {
  --error-color: rgb(216, 0, 12);
  --ticket-bg: rgb(27, 116, 231);
}

.loading {
  max-width: 69.75rem;
  max-height: 51.25rem;
}

.error {
  padding: .8rem;
  color: var(--error-color);
}

.event-item {
  margin: 2rem 0 2.8rem 0;
}

.event-detail-item {
  margin: 2rem 0 1.8rem 0;
}

.event-item h2,
.event-detail-item h2 {
  padding: 0 0 0.8rem 0;
}

.event-item p,
.event-detail-item p {
  padding: 0.8rem 0 0 0;
  font-size: 1rem;
}

.event-image {
  min-height: 12.6875rem;
  min-width: 19.0625rem;
  max-height: 26.6875rem;
  max-width: 40rem;
}

.event-info {
  margin: 0 auto;
  max-width: 45vw;
}

.ticket-container {
  display: block;
  margin: 1rem auto;
  padding: 1.2rem;
  background: var(--ticket-bg);
  width: 10rem;
  font-weight: 700;
}

.back-arrow {
  transform: rotate(180deg);
  border-radius: 50%;
  background: var(--ticket-bg);
  color: rgb(255, 255, 255);
  font-size: 1rem;
}

.favorite-btn {
  position: relative;
  height: 1.5rem;
  background: rgb(0, 0, 0);
  color: rgb(59, 58, 58);
  margin: 1rem;
  font-size: 1.6rem;
}

.favorite-btn span {
  position: absolute;
  left: 0.28125rem;
  top: -0.56rem;
}

.red {
  color: var(--error-color);
}

.favorite-btn, .back-arrow {
  padding: 0.8rem;
  border: none;
  cursor: pointer;
}

.details-wrapper {
  margin: 2.5rem;
}
nav a, a {
  margin: 0 1rem 0 1rem;
  font-size: 1.8rem;
  font-family: 'Roboto',
  sans-serif;
  text-decoration: none;
  color: white;
}

nav a:hover, 
.active {
  color: rgb(43, 228, 212);
}

button:focus {
  outline: none;
}

footer {
  background: rgb(0, 0, 0);
  font-size: 1.125rem;
  display: block;
  height: 2.5rem;
  width: 100vw;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  clear: both;
}

footer p {
  padding: 0.9rem;
}
